.drawerHead[data-v-a97f2a20] {
  display: flex;
  justify-content: space-between;
}
.w200[data-v-a97f2a20] {
  width: 250px;
  margin: 0;
}
.w100[data-v-a97f2a20] {
  width: 100px;
  margin: 0;
}
.w500[data-v-a97f2a20] {
  width: 585px;
  margin: 0;
}
.tskTable[data-v-a97f2a20] {
  width: 95%;
  margin: 0 auto;
  height: 280px;
  background-color: #F3F4F5;
  border-radius: 10px;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
}
.circle[data-v-a97f2a20] {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #CDCDCD;
  border-radius: 50%;
}
.taskList[data-v-a97f2a20] {
  display: flex;
  height: 40px;
  line-height: 40px;
  justify-content: space-around;
  align-items: center;
}
.content[data-v-a97f2a20] {
  width: 92%;
  overflow-x: auto;
  /* 启用水平滚动条 */
  white-space: nowrap;
  /* 防止内容换行 */
  min-height: 72px;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
}
.content span[data-v-a97f2a20] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-a97f2a20] {
  padding: 0;
  margin: 0;
}
.content td[data-v-a97f2a20] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  height: 32px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
